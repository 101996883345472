/**
 * NotFound
 *  Component that shows an under construction page.
 */

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faTwitterSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { GenericError } from "./GenericError";

export const UnderConstruction: React.FC = () => {
  return (
    <GenericError
      error_message="We're building something great."
      sub_message="Check back later."
    >
      {/* Social Media Links */}
      <div className="social-media-container">
        <a
          className="social-media-link"
          href="https://www.facebook.com/ACSSPH"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon size="3x" icon={faFacebookSquare} />
        </a>
        <a
          className="social-media-link"
          href="https://twitter.com/ACSSUPLB"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon size="3x" icon={faTwitterSquare} />
        </a>
        <a
          className="social-media-link"
          href="https://www.linkedin.com/company/acssuplb/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon size="3x" icon={faLinkedin} />
        </a>
      </div>
    </GenericError>
  );
};

export default UnderConstruction;
