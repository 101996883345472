import React from "react";
import UnderConstruction from "../components/GenericError/UnderConstruction";
import { Layout } from "../components/Layout";

const ContactPage: React.FC = () => {
  return (
    <Layout hasFooter={false}>
      <UnderConstruction />
    </Layout>
  );
};

export default ContactPage;
